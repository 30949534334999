import React from "react";
import styles from "./animation.module.css";
import BgHero from "../BgHero";
import { Player } from "video-react";
import video1 from "../../videos/entertainment/1.mp4";
import video2 from "../../videos/entertainment/2.mp4";
import video3 from "../../videos/entertainment/lanoce.mp4";
import vid1Thumb from "../../images/entertainment/animation.png";
import bgHero from "../../images/entertainment/maybe.png";
export default function Animation() {
  return (
    <main className={styles.main}>
      <BgHero heading="Animation" bg={bgHero} />

      <div className={styles.videoContainer}>
        <div className={styles.videos1and2}>
          <div className={styles.videoWrapper}>
            <Player
              playsInline
              src={video1}
              className={styles.video}
              poster={vid1Thumb}
            />
          </div>
          <div className={styles.videoWrapper}>
            <Player playsInline src={video2} className={styles.video} />
          </div>
        </div>
        <Player playsInline src={video3} className={styles.video} />
      </div>
    </main>
  );
}
