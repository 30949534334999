import React from "react";

export default function BgHero({ heading, bg }) {
  const hero = {
    position: "relative",
    height: "50vh",
    background: `url(${bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const overlay = {
    content: '""',
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const h1 = {
    position: "relative",
    color: "white",
    fontSize: "55px",
    marginTop: "40px",
  };
  return (
    <div style={hero}>
      <div style={overlay}>
        <h1 style={h1}>{heading}</h1>
      </div>
    </div>
  );
}
