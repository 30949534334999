import React from "react";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { Helmet } from "react-helmet";
import Animation from "../components/Entertainment/Animation";
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
const Event = loadable(() => import("../components/Entertainment/Event"));

export default function Entertainment() {
  const { t } = useTranslation("translation", { useSuspense: false });
  return (
    <>
      <Helmet>
        <title>{t("entertainment")} - La Noce</title>
        <script
          async
          defer
          crossorigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0"
          nonce="hw3GfYWT"
        ></script>
      </Helmet>

      <Nav />
      <Animation />
      <Event />
      <Footer />
    </>
  );
}
